@import 'src/lib/style/mixins.scss';

.fullWidth {
  width: 100%;
}

.item {
  border-radius: var(--container-radius);
}

.label {
  color: var(--input-color-label);
}
