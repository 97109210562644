$mobile-width: 576px;
$phablet-width: 672px;
$tablet-width: 768px;
$desktop-width: 1024px;

@mixin mobile {
  @media (max-width: #{$phablet-width - 1px}) {
    @content;
  }
}

@mixin phablet {
  @media (min-width: #{$phablet-width}) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}