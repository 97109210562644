@import 'src/lib/style/mixins.scss';

.container {
  width: 420px;
  margin: 32px auto;
  padding: 0;

  @include mobile {
    width: 100%;
    margin: 0;
  }
}

.paper {
  width: 420px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--container-radius);

  @include mobile {
    width: 100%;
    border-radius: 0;
  }
}

.logo {
  max-width: 200px;
  max-height: 50px;
  margin: 0 auto 24px;
}

.pointer {
  cursor: pointer;
}

.title {
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 24px;
}
