@import 'src/lib/style/mixins.scss';

.root {
  width: 100vw;
  height: 100vh;
  background: white;
}

.container {
  padding-top: 80px;
  padding-bottom: 80px;
}

.inner {
  position: relative;
}

.image {
  position: absolute;
  top: 50px !important;
  inset: 0;
  color: var(--mantine-color-gray-0);
}

.content {
  padding-top: 220px;
  position: relative;
  z-index: 1;

  @include mobile {
    padding-top: 120px;
  }
}

.title {
  font-family:
    Greycliff CF,
    var(--mantine-font-family);
  text-align: center;
  font-weight: 900;
  font-size: 38px;

  @include mobile {
    font-size: 32px;
  }
}

.description {
  max-width: 540px;
  margin: auto;
  margin-top: var(--mantine-spacing-xl);
  margin-bottom: calc(var(--mantine-spacing-xl) * 1.5);
}
