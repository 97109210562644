.resendButton {
  position: relative;

  &[disabled] {
    color: #ced4da;
    pointer-events: none;
  }

  .resendProgress {
    width: 137px;
    height: 1px;
    position: absolute;
    top: 16px;
  }
}
