.inputLabel {
  color: var(--input-color-label);
}

.error {
  color: var(--input-color-error);
}

.inputBox {
  border-radius: var(--input-radius);
  border-color: var(--input-color-border);

  &:focus-within {
    border-color: var(--mantine-primary-color-filled);
  }

  &[data-error] {
    color: var(--input-color-error);
    border-color: var(--input-color-error);

    input::placeholder {
      color: var(--input-color-error);
    }
  }
}
