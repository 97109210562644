.mobileGroup {
  flex-wrap: nowrap;
}

.mobileCountry {
  width: 48px;
  align-self: flex-start;

  label {
    display: flex;
    word-break: normal;

    span {
      margin-left: 4px;
    }
  }

  input {
    padding-left: 8px;
    color: #000000 !important;
    opacity: 1 !important;
    border-radius: var(--input-radius);
    border-color: var(--input-color-border);
  }
}

.mobileNumber {
  width: 100%;
  margin-top: 21px;
}
